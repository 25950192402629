<template>
    <div class="flex justify-center" style="margin-top: 5%;">
            <div class="flex flex-col lg:flex-row items-center justify-between rounded-lg bg-white border-gray-500 p-8 border-2" style="width: 70%;">
                <p class="flex justify-start">Ainda tem dúvidas?</p>
                <div class="flex justify-end mt-5 lg:mt-0">
                    <a href="https://wa.me/5547933802536?text=Eksy,%20me%20ajuda!" target="_blank">
                        <button type="button"
                            class="inline-block px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                            style="background-color: #33ccff;">Fale com a gente</button>
                    </a>
                </div>
            </div>
        </div>
</template>
<script>
export default {
    name: "ContactUs"
}
</script>
<style scoped>
</style>