
<template>
    <div class="px-8 mt-8">
        <p class="main-title">{{ module }}</p>
    <ul role="list" class="divide-y divide-gray-200 dark:divide-gray-700 mt-8">
        <li class="py-3 sm:py-4" v-for="article in articles" :key="article._id">
            <div class="flex items-center space-x-4">
                <font-awesome-icon :icon="['fas', 'fa-chevron-right']" class="mr-2"
                    style="color: #33ccff;" /><router-link
                    :to="{ path: '/artigo/' + article.slug }" class="hover:opacity-80">
                    <p>{{ article.title }}</p>
                </router-link>
            </div>
        </li>
    </ul>
    </div>
    <!-- Contact Us -->
    <contact-us></contact-us>
</template>
<script>
import ContactUs from '../components/ContactUs.vue'
import HelpdeskService from '@/services/api'

export default {
    components: {
        ContactUs,
    },
    data() {
        return {
            articles: null,
            module: this.$route.params.module
        }
    },
    mounted() {
        this.getArticlesByModule();
    },
    methods: {
        async getArticlesByModule() {
            let apiService = new HelpdeskService();
            let data = await apiService.getArticlesByModule(this.module);
            this.articles = data;
        }
    }
}
</script>

<style scoped>
.main-title {
    font-family: 'AXIS';
    font-size: 16pt;
    color: var(--eksy-black);
}
</style>