import ARTICLE_MODULES from "@/utils/constants";
import axios from "axios";

const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE,
    timeout: 180000
});

class HelpdeskService {

    async listArticles() {
        let data;
        await api.get("articles")
        .then((response) => {
            data = response.data.data;
        })
        .catch((error) => {
            console.log(error);
        });
        return data;
    }

    async getMostAccessedArticles() {
        let data;
        await api.get("articles?order=accessesCount&limit=3&sort=desc")
        .then((response) => {
            data = response.data.data;
        })
        .catch((error) => {
            console.log(error);
        });
        return data;
    }

    async search(searchValue) {
        let data;
        await api.get("articles?search=" + searchValue)
        .then((response) => {
            data = response.data.data;
        })
        .catch((error) => {
            console.log(error);
        });
        return data;
    }

    async getArticle(slug){
        let data;
        await api.get("articles/" + slug)
        .then((response) => {
            data = response.data.data;
        })
        .catch((error) => {
            console.log(error);
        });
        return data;
    }

    async getArticlesByModule(module){
        let data;
        // Invert enum to get module name equal to saved on back-end to do the search
        const modules = Object.fromEntries(Object.entries(ARTICLE_MODULES).map(a => a.reverse()));
        await api.get("articles?module=" + modules[module])
        .then((response) => {
            data = response.data.data;
        })
        .catch((error) => {
            console.log(error);
        });
        return data;
    }
}

export default HelpdeskService
