<template>
  <!-- component matched by the route will render here -->
  <div>
    <NavbarItem />
    <router-view></router-view>
    <FooterItem />
  </div>

</template>

<script>
import NavbarItem from './components/NavbarItem.vue'
import FooterItem from './components/FooterItem.vue'
export default {

  name: 'App',
  components: {
    NavbarItem,
    FooterItem
  }
};

</script>

<style global>
@font-face {
  font-family: 'AXIS';
  src: url('./assets/fonts/AXIS-Extra-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./assets/fonts/Montserrat-Regular.ttf');
}

:root {
  --eksy-blue: #33ccff;
  --eksy-black: #1a1d1c;
}

body {
  font-family: 'Montserrat-Regular', Arial, Helvetica, sans-serif;
}

.axis-text {
  font-family: 'AXIS', Arial, Helvetica, sans-serif;
}

/* .text-black{
  color: #1a1d1c;
}

.eksy-blue{
  color: #33ccff;
} */
</style>
