<template>
    <div id="search-section">
        <div class="container mx-auto max-w-7xl px-4 py-12">
            <p class="search-title mb-5 axis-text text-center" style="color: var(--eksy-black);">Como podemos ajudar você?
            </p>
            <form class="mx-auto max-w-7xl" @submit.prevent="searchArticle">
                <label for="default-search" class="font-medium sr-only">Search</label>
                <div class="relative">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <font-awesome-icon :icon="['fas', 'fa-magnifying-glass']" class="text-gray-500 h-5 w-5" />
                    </div>
                    <input type="search" id="default-search" v-model="searchValue"
                        class="block w-full p-4 pl-10 text-sm text-gray-500 border border-gray-300 rounded-lg bg-gray-50"
                        placeholder="Qual a sua dúvida?" @input="validateSearch" @blur="hideMessage">
                    <button class="text-white axis-text absolute 
                            right-2.5 bottom-2.5 rounded-lg text-sm px-4 py-2 hover:opacity-80"
                        style="background-color: var(--eksy-black);">Pesquisar</button>
                </div>
            </form>
            <span v-if="errorMessage" class="text-red-500 ml-6" style="font-weight: 600;">{{ errorMessage }}</span>
        </div>
    </div>
</template>
    
<script>
export default {
    name: "SearchSection",
    data() {
        return {
            inputValue: this.searchInputValue ? this.searchInputValue : '',
            errorMessage: null
        }
    },
    props: ['searchInputValue'],
    computed: {
        searchValue: {
            get() {
                return this.inputValue
            },
            set(value) {
                this.inputValue = value
            }
        }
    },
    methods: {
        validateSearch() {
            if (this.searchValue !== '') {
                this.errorMessage = null;
            } else {
                this.errorMessage = 'Campo pesquisa está vazio.'
            }
        },

        hideMessage(){
            this.errorMessage = null;
        },
    
        searchArticle() {
            if (this.searchValue) {
                this.$router.push({ name: 'search', query: { search: this.searchValue } });
            }else{
                this.errorMessage = 'Campo pesquisa está vazio.'
            }
        }
    }
}
</script>
<style scoped>
#search-section {
    background-color: #33ccff;
}

.search-title {
    font-size: 28pt;
}

@media (min-width: 375px) and (max-width: 640px) {
    .search-title {
        font-size: 22pt;
    }
}
</style>