import { createApp } from 'vue'
import App from './App.vue'
import 'flowbite'
import axios from 'axios'
import router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'

import './assets/app.css'

library.add(faChevronRight,faInstagram, faYoutube, faMagnifyingGlass)

const app = createApp(App)
app.config.globalProperties.$axios = axios
app.component('font-awesome-icon', FontAwesomeIcon).use(router).mount('#app')
