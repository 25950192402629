const ARTICLE_MODULES = {
    'MARKETING': "Marketing",
    'COLLECTION': "Avaliação",
    'TRAINING': "Treinamento",
    'GENERAL': "Geral",
    'CLIENTS': "Clientes",
    'SIGNATURE': "Assinatura",
    'FINANCIAL': "Financeiro",
    'EKSYPAY': 'Eksy Pay'
}


export default ARTICLE_MODULES