
<template>
    <div class="mx-auto max-w-7xl px-4 sm:px-6" v-if="article">
        <p class="article-title mt-8">{{ article.title }}</p>
        <div class="mt-2" v-if="article.type && article.type == 'PLUS'">
            <span class="bg-green-100 text-green-800 text-sm font-medium px-4 py-1 rounded-full
             dark:bg-green-900 dark:text-green-300">Funcionalidade disponível apenas no plano Eksy Plus</span>
        </div>
        <div class="mt-8" v-if="article.explainVideo">
            <p class="mb-4">Se preferir, assista ao vídeo com o passo a passo:</p>
            <iframe class="sm:h-auto sm:w-auto md:h-80 md:w-1/2" :src="article.explainVideo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div id="raw-html-section" class="mt-8" v-html="article.text"></div>
    </div>

    <!-- Contact Us -->
    <contact-us></contact-us>
</template>
<script>
import router from '@/router';
import ContactUs from '../components/ContactUs.vue'
import HelpdeskService from '@/services/api'

export default {
    components: {
        ContactUs,
    },
    data() {
        return {
            article: Array,
        }
    },
    mounted() {
        this.getArticle();
    },
    methods:{
        async getArticle(){
            let apiService = new HelpdeskService();
            let data = await apiService.getArticle(this.$route.params.slug)
            if(!data){
                router.push({ name: 'NotFound' });
            }
            this.article = data
        }
    }
}
</script>

<style scoped>
.article-title {
    font-family: 'AXIS';
    font-size: 16pt;
    color: var(--eksy-black);
}

#raw-html-section >>> .steps-title{
    font-family: 'AXIS';
    font-size: 14pt;
    color: var(--eksy-black);
}

#raw-html-section >>> p{
    margin: 16px;
} 
</style>