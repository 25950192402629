<template>
  <div class="mx-auto bg-white">
    <div class="mx-auto max-w-7xl px-4 h-20 flex items-center justify-between">
      <div>
        <a href="/">
          <img class="h-8 w-auto sm:h-8" :src="require(`@/assets/img/eksy-navbar-logo.png`)" alt="Logo Eksy">
        </a>
      </div>
      <div>
        <a href="https://eksy.com.br/blog/"
          class="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900" target="_blank">Blog</a>
        <a href="https://web.eksy.com.br/"
          class="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent px-4 py-2 text-base font-medium text-white hover:opacity-50"
          style="background-color: #33ccff;" target="_blank">Log in</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarItem"
}
</script>
<style scoped>

</style>