<template>
  <div class="mx-auto bg-white border-gray-200 border-t-2 mt-10 footer-container">
    <div class="max-w-7xl mx-auto px-4 h-20 flex items-center justify-between">
      <div class="text-gray-500 text-sm">
        © EKSY {{ year }}
      </div>
      <div>
        <a href="https://www.instagram.com/eksyapp/" target="_blank"> <font-awesome-icon :icon="['fab', 'fa-instagram']"
            class="text-gray-500 h-8 w-8" />
        </a>
        <a href="https://www.youtube.com/@eksyapp" target="_blank"> <font-awesome-icon :icon="['fab', 'fa-youtube']"
            class="text-gray-500 h-8 w-8 ml-4" />
        </a>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "FooterItem",
  data() {
        return {
            year: new Date().getFullYear(),
        }
    },
}
</script>
<style scoped>
</style>