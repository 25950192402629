import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ArticleView from '../views/ArticleView.vue'
import SearchResultView from '../views/SearchResultView.vue'
import ArticlesListView from '../views/ArticlesListView.vue'
import NotFound from '../views/NotFound.vue'


const routes = [
    {
        path: '/',
        component: HomeView
    },
    {
        path: '/artigo/:slug',
        component: ArticleView
    },
    {
        path: '/artigo/search',
        query: { search: ':search' },
        name: 'search',
        component: SearchResultView
    },
    {
        path: '/artigos/:module',
        component: ArticlesListView
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router