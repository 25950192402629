
<template>
    <!-- Search -->
    <div>
        <search-section></search-section>
        <div class="home-container mx-auto max-w-7xl px-4 mt-8">
            <!-- Major Doubts -->
            <div>
                <p class="container-title mb-5">Perguntas frequentes</p>
                <ul>
                    <li class="mt-3" v-for="article in mostAccessedArticles" :key="article._id"><font-awesome-icon
                            :icon="['fas', 'fa-chevron-right']" class="mr-2" style="color: #33ccff;" /><router-link
                            :to="{ path: '/artigo/' + article.slug }" class="hover:opacity-80">
                            <span>{{ article.title }}</span>
                        </router-link></li>
                </ul>
            </div>

            <!-- Articles-->
            <div class="w-full">
                <p class="container-title mt-8">Encontre artigos</p>
                <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-5">
                    <articles-card :title="moduleConstant[module]" v-for="(items, module) in articles" :key="module">
                        <ul role="list" class="divide-y divide-gray-200 dark:divide-gray-700">
                            <li class="py-3 sm:py-4" v-for="article in items.slice(0, 4)" :key="article._id">
                                <div class="flex items-center space-x-4">
                                    <font-awesome-icon :icon="['fas', 'fa-chevron-right']" class="mr-2"
                                        style="color: #33ccff;" /><router-link
                                        :to="{ path: '/artigo/' + article.slug }"
                                        class="hover:opacity-80">
                                        <p>{{ article.title }}</p>
                                    </router-link>
                                </div>
                            </li>
                            <li class="py-3 sm:py-4" v-if="items.length > 4"><router-link :to="{ path: '/artigos/' + moduleConstant[module] }"
                                    class="hover:opacity-80">
                                    <p class="ml-9 font-semibold" style="color: var(--eksy-blue);">Ver mais artigos</p>
                                </router-link></li>
                        </ul>
                    </articles-card>
                </div>
            </div>

            <!-- Contact us-->
            <contact-us></contact-us>

        </div>
    </div>
</template>
<script>
import SearchSection from '../components/SearchSection.vue'
import ContactUs from '../components/ContactUs.vue'
import ArticlesCard from '../components/ArticlesCard.vue'
import HelpdeskService from '@/services/api'
import ARTICLE_MODULES from '@/utils/constants'

export default {
    components: {
        SearchSection,
        ContactUs,
        ArticlesCard
    },
    data() {
        return {
            mostAccessedArticles: null,
            articles: null,
            apiService: new HelpdeskService(),
            moduleConstant: ARTICLE_MODULES
        }
    },
    mounted() {
        this.getMostAccessedArticles();
        this.getArticles();
    },
    methods: {
        async getMostAccessedArticles() {
            let mostAccessedArticles = await this.apiService.getMostAccessedArticles()
            this.mostAccessedArticles = mostAccessedArticles
        },

        async getArticles() {
            let articles = await this.apiService.listArticles();
            this.articles = articles.reduce((group, article) => {
                const { module } = article;
                group[module] = group[module] ?? [];
                group[module].push(article);
                return group;
            }, {});
        }
    }
}
</script>

<style scoped>
.home-container {
    color: var(--eksy-black);
}

.container-title {
    font-family: 'AXIS';
    font-size: 16pt;
}
</style>