<template>
    <!-- Search -->
    <search-section :searchInputValue="searchValue"></search-section>
    <div class="px-8 mx-auto max-w-7xl">
        <div class="search-result-title mt-8">
            <p v-if="count == 1"> {{ count }} resultado para a pesquisa "{{ searchValue }}"</p>
            <p v-else-if="count > 1"> {{ count }} resultados para a pesquisa "{{ searchValue }}"</p>
            <p v-else>Nenhum resultado foi encontrado para a pesquisa "{{ searchValue }}"</p>
        </div>

        <!-- Articles results -->
        <ul role="list" class="divide-y divide-gray-200 dark:divide-gray-700 mt-5">
            <li class="py-3 sm:py-4" v-for="article in searchResult" :key="article._id">
                <div class="flex items-center space-x-4">
                    <font-awesome-icon :icon="['fas', 'fa-chevron-right']" class="mr-2"
                        style="color: #33ccff;" /><router-link
                        :to="{ path: '/artigo/' + article.slug }" class="hover:opacity-80">
                        <p class="search-article-title">{{ article.title }}</p>
                    </router-link>
                </div>
            </li>
        </ul>
    </div>

    <!-- Contact us-->
    <contact-us></contact-us>
</template>
<script>
import SearchSection from '../components/SearchSection.vue'
import ContactUs from '../components/ContactUs.vue'
import HelpdeskService from '@/services/api'

export default {
    components: {
        SearchSection,
        ContactUs,
    },
    data() {
        return {
            count: null,
            searchResult: null,
            searchValue: this.$route.query.search
        }
    },
    mounted() {
        this.searchResults()
    },
    watch: {
        //This watch was implemented because when doing another search the data from the page wasn't refreshed, the component wasn't created again
        '$route'() {
            this.searchValue = this.$route.query.search
            this.searchResults()
        }
    },
    methods: {
        async searchResults() {
            let apiService = new HelpdeskService();
            let articles = await apiService.search(this.searchValue);
            this.searchResult = articles
            this.count = articles.length
        }
    }
}
</script>

<style scoped>
.search-result-title {
    font-family: 'AXIS';
    font-size: 16pt;
    color: var(--eksy-black);
}

.search-article-title {
    font-family: 'AXIS';
    font-size: 12pt;
    color: var(--eksy-black);
}
</style>