<template>
    <div class="p-4 bg-white border rounded-lg sm:p-8">
        <div class="flex items-center justify-between mb-4">
            <h5 class="text-xl font-bold leading-none" style="color: var(--eksy-black)">{{ title }}</h5>
        </div>
        <div class="flow-root">
           <slot></slot>
        </div>
    </div>

</template>
    
<script>
export default {
    name: "ArticlesCard",
    props: {
        title: String,
    }
}
</script>
<style scoped>

</style>